import React from 'react';
import { ChevronRight, Crown, HelpCircle } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';

function LatestTest({ examInfo, gradeProgress = 1.0, prProgress = 1.0 }) {
  const navigate = useNavigate();

  const handleResultClick = () => {
    navigate(`/myresult/${examInfo.examInfo.tid}`);
  };

  return (
    <div className="w-[316px]">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2 ml-2">
          <p className="text-[20px] font-bold text-[#231F20]">
            나의 상태
          </p>
          <HelpCircle className="w-[15px] h-[15px] text-[#A3A1A2]" />
        </div>
        <Link 
          to="/ranking" 
          className="flex items-center gap-1 px-3 h-[20px] border border-[#A3A1A2] rounded-[8px] hover:opacity-80"
        >
          <Crown className="w-3 h-3 text-[#A3A1A2]" />
          <span className="text-[10px] font-medium text-[#A3A1A2]">전체 랭킹</span>
        </Link>
      </div>
      {/* 배경 투명도 증가 및 블러 효과 강화 */}
      <div className="relative backdrop-blur-md bg-white/30 rounded-[16px] p-4 w-full shadow-[0_0_6px_0_rgba(0,0,0,0.1)] border border-white/20">
        {/* 헤더 섹션 - 폰트 색상 변경 */}
        <div className="flex justify-between items-center mb-12">
          <div className="flex items-center gap-2">
            <span className="text-[14px] font-bold text-[#231F20]">{examInfo.subject}</span>
            <div 
              onClick={handleResultClick}
              className="flex items-center"
            >
              <span className="w-[38px] h-[16px] flex items-center justify-center bg-[#4ABC85] text-[#FFFFFF] rounded-full text-[8px]">
                {examInfo.status}
              </span>
              <ChevronRight className="w-4 h-4 text-[#A3A1A2] ml-1 cursor-pointer hover:opacity-90" />
            </div>
          </div>
          <span className="text-[12px] text-[#A3A1A2]">{examInfo.date}</span>
        </div>

        {/* 원형 그래프 섹션 */}
        <div className="flex justify-center gap-[90px] mb-12">
          {/* 등급 원형 (왼쪽) */}
          <div className="relative w-[67.5px] h-[67.5px]">
            <svg
              className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
              viewBox="0 0 120 120"
            >
              <defs>
                <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#4ABC85" />
                  <stop offset="100%" stopColor="#4ABC85" />
                </linearGradient>
              </defs>

              {/* 배경 원 */}
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="#EEEEEE"
                strokeWidth="18"
                strokeLinecap="round"
                strokeDasharray="228.38 76.38"
                transform="rotate(180, 60, 60)"
              />
              
              {/* 진행도 원 */}
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="url(#progressGradient)"
                strokeWidth="18"
                strokeLinecap="round"
                strokeDasharray={`${228.38 * gradeProgress} ${228.38 * (1 - gradeProgress) + 76.38}`}
                transform="translate(120, 0) scale(-1, 1) rotate(90, 60, 60)"
              />

              {/* 텍스트를 다른 요소들 위에 렌더링되도록 마지막으로 이동 */}
              <text
                x="26"
                y="110"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="start"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                5등급
              </text>

              <text
                x="18"
                y="80"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="end"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                만점
              </text>

              {/* 4개의 점들도 새로운 반지름으로 조정 */}
              <circle
                cx={60 + 48.43 * Math.cos((180 + 270 * 0.2) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((180 + 270 * 0.2) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((180 + 270 * 0.4) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((180 + 270 * 0.4) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((180 + 270 * 0.6) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((180 + 270 * 0.6) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((180 + 270 * 0.8) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((180 + 270 * 0.8) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />

              {/* 움직이는 삼각형 화살표 */}
              <path
                d="M 0,-4 L 3,2 L -3,2 Z"
                fill="#4ABC85"
                transform={`
                  translate(60, 60)
                  rotate(${180 - (gradeProgress * 270)}),
                  translate(0, -32)
                `}
              />
            </svg>
            <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center z-10">
              <div className="text-center -mt-2">
                <div className="text-[30px] font-bold text-[#FFFFFF]">{examInfo.grade}</div>
                <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-2">{examInfo.gradeText}</div>
              </div>
            </div>
          </div>

          {/* 입 모양 곡선 수정 */}
          <div className="absolute left-1/2 top-[45%] -translate-x-1/2 z-10 mb-8">
            <svg width="44" height="40" viewBox="0 0 60 40">
              <path
                d={`M 9 20 Q 29 ${Math.abs(gradeProgress - prProgress) <= 0.1
                  ? 30  // 위로 휨 (웃는 표정) - 0.1 이하
                  : Math.abs(gradeProgress - prProgress) < 0.2
                    ? 20  // 직선 (0.1 초과 ~ 0.2 미만)
                    : 10  // 아래로 휨 (우는 표정) - 0.2 이상
                  } 51 20`}
                stroke="#4ABC85"
                strokeWidth="13"
                fill="none"
                strokeLinecap="round"
              />
            </svg>
          </div>
          {/* PR 원형 */}
          <div className="relative w-[67.5px] h-[67.5px]">
            <svg
              className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
              viewBox="0 0 120 120"
            >
              <defs>
                <linearGradient id="progressGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#4ABC85" />
                  <stop offset="100%" stopColor="#4ABC85" />
                </linearGradient>
              </defs>

              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="#EEEEEE"
                strokeWidth="18"
                strokeLinecap="round"
                strokeDasharray="228.38 76.38"
                transform="rotate(90, 60, 60)"
              />
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="url(#progressGradient2)"
                strokeWidth="18"
                strokeLinecap="round"
                strokeDasharray={`${228.38 * prProgress} ${228.38 * (1 - prProgress) + 76.38}`}
                transform="rotate(90, 60, 60)"
              />

              
              {/* 텍스트를 다른 요소들 위에 렌더링되도록 마지막으로 이동 */}
              <text
                x="75"
                y="110"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="start"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                0
              </text>

              <text
                x="120"
                y="80"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="end"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                10000
              </text>

              {/* 4개의 점들도 새로운 반지름으로 조정 */}
              <circle
                cx={60 + 48.43 * Math.cos((90 + 270 * 0.2) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((90 + 270 * 0.2) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((90 + 270 * 0.4) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((90 + 270 * 0.4) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((90 + 270 * 0.6) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((90 + 270 * 0.6) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />
              <circle
                cx={60 + 48.43 * Math.cos((90 + 270 * 0.8) * Math.PI / 180)}
                cy={60 + 48.43 * Math.sin((90 + 270 * 0.8) * Math.PI / 180)}
                r="1.7"
                fill="#FFFFFF"
              />

              {/* 움직이는 삼각형 화살표 - y축 대칭 */}
              <path
                d="M 0,-4 L 3,2 L -3,2 Z"
                fill="#4ABC85"
                transform={`
                  translate(60, 60)
                  scale(-1, 1)
                  rotate(${180 - (prProgress * 270)}),
                  translate(0, -32)
                `}
              />
            </svg>
            <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center">
              <div className="text-center">
                <div className="text-[18px] font-bold text-[#FFFFFF]">{examInfo.pr}</div>
                <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-1" >PR</div>
              </div>
            </div>
          </div>
        </div>

        {/* 구분선 */}
        <div className="w-full h-[1px] bg-[#EEEEEE] mb-4"></div>

        {/* 수직 정보 */}
        <div className="flex flex-col gap-2 mb-4 px-1">
          <div className="flex justify-between">
            <span className="text-[#231F20]/60 text-[12px]">백분위</span>
            <span className="text-[#231F20] font-medium text-[12px]">{examInfo.rank}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-[#231F20]/60 text-[12px]">다음 등급까지 남은 학습시간</span>
            <span className="text-[#231F20] font-medium text-[12px]">{examInfo.remainTime}</span>
          </div>
        </div>

        {/* 수평 정보 - 회색 박스 */}
        <div className="w-full bg-[#f1f1f1] rounded-[16px] flex items-center justify-center px-4 h-[50px]">
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1">
              <span className="text-[#231F20]/60 text-[11px]">회차</span>
              <span className="text-[#231F20] text-[11px]">{examInfo.examInfo.round}</span>
              <span className="text-[#231F20]/60 text-[8px]">· TID</span>
              <span className="text-[#231F20] text-[8px]">{examInfo.examInfo.tid}</span>
            </div>
            <div className="h-3 w-[1px] bg-[#cccccc]"></div>
            <div className="flex items-center gap-1">
              <span className="text-[#231F20]/60 text-[11px]">난이도</span>
              <span className="text-[#231F20] font-medium text-[11px]">{examInfo.examInfo.difficulty}</span>
            </div>
            <div className="h-3 w-[1px] bg-[#cccccc]"></div>
            <div className="flex items-center gap-1">
              <span className="text-[#231F20]/60 text-[11px]">문제 수</span>
              <span className="text-[#231F20] font-medium text-[11px]">{examInfo.examInfo.problems}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestTest;
