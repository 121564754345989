import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/newHeader';
import StudentInformation from '../components/Home/StudentInfomation';
import axios from 'axios';
import StampList from '../components/Home/StampList';
import Footer from '../components/Footer';
import LatestTest from '../components/Home/LatestTest';
import RankingList from '../components/Home/RankingList';

function Home() {
  const navigate = useNavigate();
  const [studentInfo, setStudentInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stamps, setStamps] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [rankData, setRankData] = useState([]);
  const [selectedStampId, setSelectedStampId] = useState(null);

  // 날짜 포맷팅 함수
  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setStudentInfo(null);
      
      try {
        // 홈 데이터 가져오기
        const homeResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/home/v2`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );

        if (homeResponse.data.status === 'success') {
          const { data } = homeResponse.data;
          
          setStudentInfo({
            student_name: data.student_name,
            school_name: data.school_name,
            school_grade: data.school_grade,
          });

          // 랭크 데이터 가져오기 - 엔드포인트 수정
          const rankResponse = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/elotest/rank`,
            {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
              }
            }
          );

          if (rankResponse.data.status === 'success') {
            setRankData(rankResponse.data.data);
          }

          // stamp_info 처리 (랭크 데이터와 결합)
          const stampList = data.stamp_info.map(stamp => {
            const rankInfo = rankResponse.data.data.find(
              rank => rank.stamp_id === stamp.stamp_id
            );
            
            return {
              stamp_id: stamp.stamp_id,
              tree_name: stamp.tree_name,
              edu_ver: stamp.edu_ver,
              exam_info: stamp.exam_info,
              rating: rankInfo?.rating?.toString() || "-",  // rating 필드 사용
              rank: rankInfo?.rank?.toString() || "-",      // rank 필드 사용
              delta: rankInfo?.delta?.toString() || "-",    // delta 필드 사용
            };
          });

          setStamps(stampList);

          // 스탬프가 있을 경우 첫 번째 스탬프를 선택 상태로 설정
          if (stampList && stampList.length > 0) {
            const firstStamp = stampList[0];
            setSelectedStampId(firstStamp.stamp_id);
            if (firstStamp.exam_info) {
              setSelectedExam(convertToExamInfo(firstStamp));
            }
          }
        } else if (homeResponse.data.status_code === 404) {
          // status_code가 404인 경우 예약 페이지로 리다이렉트
          navigate('/schedule/book', { replace: true });
          return;
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        } else if (error.response?.status === 404) {
          // HTTP 404 에러인 경우 예약 페이지로 리다이렉트
          navigate('/schedule/book', { replace: true });
          return;
        }
      }
    };

    fetchData();
  }, [navigate]);

  // 시험 정보 변환 함수
  const convertToExamInfo = (stampInfo) => {
    const examInfo = stampInfo.exam_info;
    const analysis = examInfo.analysis || {};
    
    // 백분위 계산 (percentage * 100, 반올림)
    const percentageValue = analysis.percentage 
      ? Math.round(analysis.percentage * 100) 
      : null;

    // 남은 학습시간 계산 (remain_time / 60, 올림)
    const remainTimeHours = analysis.remain_time 
      ? Math.ceil(analysis.remain_time / 60)
      : null;
    
    return {
      subject: stampInfo.tree_name,
      status: examInfo.exam_type || "",
      date: formatExamDate(examInfo.updated_at),
      grade: analysis.target_grade !== undefined ? analysis.target_grade + 1 : "-",
      gradeText: "등급",
      pr: stampInfo.rating || "-",
      rank: percentageValue ? `상위 ${percentageValue}%` : "-",
      remainTime: remainTimeHours ? `${remainTimeHours}시간` : "-",
      examInfo: {
        round: examInfo.exam_count,
        tid: examInfo.exam_id,
        problems: examInfo.length,
        difficulty: (examInfo.difficulty / 100).toFixed(2),
        analysis: analysis
      }
    };
  };

  // 남은 학습시간 계산 함수
  const calculateRemainTime = (analysis) => {
    if (!analysis || !analysis.target_grade) return "-";
    
    // 목표 등급까지 남은 진도율
    const remainProgress = 1 - (analysis.progress || 0);
    
    // 예상 학습시간 (시간 단위)
    const estimatedHours = Math.ceil(remainProgress * 100); // 예시: 1% 당 1시간
    
    return `${estimatedHours}시간`;
  };

  // 레이팅 계산 함수 (stamp_record 기반)
  const calculateRating = (stampRecord) => {
    // stamp_record를 사용한 레이팅 계산 로직 구현 필요
    return stampRecord ? stampRecord[1] : "-";
  };

  // 랭크 계산 함수 (stamp_record 기반)
  const calculateRank = (stampRecord) => {
    // stamp_record를 사용한 랭크 계산 로직 구현 필요
    return stampRecord ? stampRecord[0] : "-";
  };

  const handleExamClick = (examId) => {
    navigate(`/myresult/${examId}`);
  };

  // 시험 결과 페이지로 이동하는 함수 수정
  const handleRankingClick = (stampInfo) => {
    if (stampInfo.exam_info) {
      setSelectedStampId(stampInfo.stamp_id);
      setSelectedExam(convertToExamInfo(stampInfo));
    }
  };

  if (isLoading) return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-700 font-medium animate-pulse">로딩중...</p>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col min-h-screen">
      {/* 단색 배경 */}
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      
      {/* Glassmorphism 오버레이 제거 */}
      
      {/* 실제 콘텐츠 */}
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex flex-col items-center pt-[84px] space-y-[20px] pb-[80px]">
          <StudentInformation studentInfo={studentInfo} />
          {selectedExam && (
            <LatestTest 
              examInfo={selectedExam}
              gradeProgress={(() => {
                const targetGrade = selectedExam.examInfo.analysis?.target_grade;
                const progress = selectedExam.examInfo.analysis?.progress || 0;
                
                switch(targetGrade) {
                  case 0: return 0.8 + 0.2 * progress;
                  case 1: return 0.6 + 0.2 * progress;
                  case 2: return 0.4 + 0.2 * progress;
                  case 3: return 0.4 * progress;
                  default: return progress;
                }
              })()}
              prProgress={selectedExam.pr !== "-" ? Number(selectedExam.pr) / 10000 : 0}
              onClick={() => selectedExam.examInfo.exam_id && 
                navigate(`/myresult/${selectedExam.examInfo.exam_id}`)}
            />
          )}
          <RankingList 
            rankings={stamps}
            onRankingClick={handleRankingClick}
            selectedStampId={selectedStampId}
          />
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Home;