import React from 'react';
import logoStudent from '../../assets/logo_student.png';

function StudentInformation({ studentInfo }) {
  if (!studentInfo) return null;

  return (
    <div className="w-[316px] h-[50px]">
      <div className="backdrop-blur-md bg-white/30 rounded-[16px] h-full w-full flex items-center shadow-[0_0_6px_0_rgba(0,0,0,0.1)] border border-white/20">
        <div className="flex items-center justify-between w-full px-[20px]">
          <div className="flex items-center space-x-[20px]">
            <img 
              src={logoStudent} 
              alt="학생 로고" 
              className="w-[30px] h-[30px]"
            />
            <div className="flex items-baseline space-x-1">
              <span className="text-[16px] font-bold text-[#231F20]">
                {studentInfo.student_name}
              </span>
              <span className="text-[12px] font-normal text-[#231F20]">
                학생
              </span>
            </div>
          </div>
          <div className="text-right">
            <span className="text-[12px] font-normal text-[#7B7979]">
              {studentInfo.school_name} {studentInfo.school_grade}학년
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentInformation;
