import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/newHeader';
import Footer from '../components/Footer';
import axios from 'axios';
import logoStudent from '../assets/logo_student.png';

function Ranking() {
  const navigate = useNavigate();
  const [rankingData, setRankingData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTree, setSelectedTree] = useState('전체');
  const [treeNames, setTreeNames] = useState(['전체']);

  useEffect(() => {
    const fetchRankingData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/elotest/rank`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );

        if (response.data.status === 'success') {
          setRankingData(response.data.data);
          const uniqueTreeNames = ['전체', ...new Set(response.data.data.map(item => item.tree_name))];
          setTreeNames(uniqueTreeNames);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      }
    };

    fetchRankingData();
  }, [navigate]);

  const filteredRankingData = rankingData?.filter(item => 
    selectedTree === '전체' || item.tree_name === selectedTree
  );

  if (isLoading) return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-700 font-medium animate-pulse">로딩중...</p>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex flex-col items-center pt-[84px] pb-[120px]">
          <div className="w-[316px]">
            <div className="mb-6">
              <select
                value={selectedTree}
                onChange={(e) => setSelectedTree(e.target.value)}
                className={`w-[158px] h-[42px] px-4 rounded-[16px] border border-gray-200 text-[14px] font-medium ${
                  selectedTree === '전체' ? 'text-[#A3A1A2]' : 'text-[#231F20]'
                } bg-white appearance-none cursor-pointer`}
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='${
                    selectedTree === '전체' ? '%23A3A1A2' : '%23231F20'
                  }' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 16px center'
                }}
              >
                {treeNames.map((treeName) => (
                  <option 
                    key={treeName} 
                    value={treeName}
                    className={treeName === selectedTree ? 'text-[#231F20]' : 'text-[#A3A1A2]'}
                  >
                    {treeName}
                  </option>
                ))}
              </select>
            </div>

            <div className="space-y-4">
              {filteredRankingData && filteredRankingData.map((item, index) => (
                <div 
                  key={item.stamp_id}
                  className={`flex items-center justify-between py-2 px-3 rounded-[8px] ${
                    item.my_stamp ? 'bg-[#EEEEEE]' : 'bg-[#FAFAFA]'
                  }`}
                >
                  <div className="flex items-center gap-4">
                    <span className={`w-6 text-[14px] font-bold ${
                      index < 3 
                        ? 'text-green-600' 
                        : 'text-[#A3A1A2] font-normal'
                    }`}>{index + 1}</span>
                    <img 
                      src={logoStudent} 
                      alt="학생 로고" 
                      className="w-[30px] h-[30px]"
                    />
                    <div>
                      <p className="text-[12px] font-semibold text-[#231F20] select-none pointer-events-none">{item.student_email}</p>
                      <p className="text-[10px] text-[#7B7979] font-normal">{item.tree_name}</p>
                    </div>
                  </div>
                  <div>
                    <p className={`text-[14px] font-bold ${
                      index < 3 
                        ? 'text-green-600' 
                        : 'text-[#231F20] font-normal'
                    }`}>{item.rating}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Ranking;
