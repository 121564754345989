import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import NewHeader from '../components/newHeader';
import Footer from '../components/Footer';
import logoStudent from '../assets/logo_student.png';

function MenuPage() {
  const [studentName, setStudentName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');
    if (name) setStudentName(name);
    if (email) setStudentEmail(email);
    setIsLoading(false);
  }, []);

  if (isLoading) return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      <div className="relative z-20 flex flex-col min-h-screen">
        <NewHeader />
        <main className="flex-grow flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-700 font-medium animate-pulse">로딩중...</p>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#ffffff]">
      <NewHeader />
      <div className="pt-[84px] pb-[120px] flex flex-col items-center">
        <div className="w-[316px] mb-6">
          <h1 className="text-[18px] font-bold text-[#231F20] mb-6">
            전체 메뉴
          </h1>
          <div className="flex items-start space-x-[20px]">
            <img 
              src={logoStudent} 
              alt="학생 로고" 
              className="w-[40px] h-[40px]"
            />
            <div className="flex flex-col">
              <div className="flex items-baseline space-x-1">
                <span className="text-[16px] font-bold text-[#231F20]">
                  {studentName}
                </span>
                <span className="text-[12px] font-normal text-[#231F20]">
                  학생
                </span>
              </div>
              <span className="text-[12px] font-normal text-[#7B7979] mt-1">
                {studentEmail}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[316px]">
          <div className="space-y-4">
            <MenuItem 
              label="카이테스트" 
              path="/kytest" 
              showArrow={true}
            />
            <Divider />
            <MenuItem 
              label="랭킹" 
              path="/ranking" 
              showArrow={true}
            />
            <Divider />
            <MenuItem 
              label="공지사항" 
              showArrow={true}
              disabled={true}
            />
            <Divider />
            <MenuItem 
              label="FAQ" 
              showArrow={true}
              disabled={true}
            />
            <Divider />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function MenuItem({ label, path, showArrow = false, disabled = false }) {
  const navigate = useNavigate();
  
  const handleClick = () => {
    if (!disabled && path) {
      navigate(path);
    }
  };
  
  return (
    <button 
      onClick={handleClick}
      className={`w-full flex justify-between items-center px-4 py-3 ${!disabled && 'hover:text-[#4ABC85]'} transition-colors duration-200`}
      disabled={disabled}
    >
      <span className="text-[14px] font-medium text-[#231F20]">{label}</span>
      {showArrow && <ChevronRight className="w-5 h-5 text-[#A3A1A2]" />}
    </button>
  );
}

function Divider() {
  return <div className="h-[1px] bg-[#F2F2F2]" />;
}

export default MenuPage; 