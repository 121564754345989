import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Share, CreditCard, X, Settings, TabletSmartphone, Clock } from 'lucide-react';
import Header from '../components/Header';
import logoAnalysis from '../assets/logo_analysis.png';
import { useNavigate, useParams, Navigate } from 'react-router-dom';


function ResultPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const endpoint = `${process.env.REACT_APP_API_BASE_URL}/api/result/${code}`;

        const response = await fetch(endpoint);
        const json = await response.json();

        if (json.status === 'success' && isMounted) {
          setAnalysis(json.data.analysis);
          setExamInfo(json.data);
          setIsLoading(false);
        } else if (json.status_code === 423) {
          // 만료된 코드 처리
          setIsExpired(true);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code]);

  const graphData = useMemo(() => {
    if (!analysis) return null;

    const timeData = analysis.time_remain_reference.map(time => Math.floor(time / 60));

    const getGradeText = (grade) => {
      if (grade === 0) return "만점";
      if (grade > 5) return "6등급";  // 6등급 이상은 그냥 6등급으로 표시
      return `${grade}등급`;
    };

    const getTimeDataIndex = (targetGrade, offset = 0) => {
      const index = timeData.length - targetGrade - 1 + offset;
      return Math.min(Math.max(index, 0), timeData.length - 1); // 배열 범위를 벗어나지 않도록
    };

    return {
      position: analysis.target_grade,
      data: [
        { grade: analysis.target_grade + 2, time: timeData[getTimeDataIndex(analysis.target_grade, 1)], label: getGradeText(analysis.target_grade + 2) },
        { grade: analysis.target_grade + 1, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade + 1) },
        { grade: analysis.target_grade, time: Math.floor((analysis.remain_time) / 60), label: "현재 위치" },
        { grade: analysis.target_grade, time: timeData[getTimeDataIndex(analysis.target_grade)], label: getGradeText(analysis.target_grade) }
      ]
    };
  }, [analysis]);

  const getGoalTime = () => {
    if (!analysis) return 0;
    return Math.floor(analysis.remain_time / 60);
  };

  // gradeProgress 계산 로직 수정
  const gradeProgress = (() => {
    const targetGrade = analysis?.target_grade;
    const progress = analysis?.progress || 0;

    switch (targetGrade) {
      case 0: return 0.8 + 0.2 * progress;
      case 1: return 0.6 + 0.2 * progress;
      case 2: return 0.4 + 0.2 * progress;
      case 3: return 0.4 * progress;
      default: return progress;
    }
  })();

  // PR 값 계산 함수 수정
  const getPrValue = () => {
    return examInfo?.exam_info?.rating || 0;
  };

  const handleShare = () => {
    setIsShareModalOpen(true);
  };

  const handleCopyLink = () => {
    const currentUrl = window.location.href;

    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      // Safari 전용 복사 로직
      const tempInput = document.createElement('input');
      tempInput.value = currentUrl;
      tempInput.style.cssText = 'position: absolute; left: -9999px; top: -9999px';
      document.body.appendChild(tempInput);
      tempInput.focus();
      tempInput.setSelectionRange(0, 99999);

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setToastMessage('URL이 클립보드에 복사되었습니다.');
        } else {
          throw new Error('복사 실패');
        }
      } catch (err) {
        setToastMessage('URL 복사에 실패했습니다. 직접 선택해주세요.');
      } finally {
        document.body.removeChild(tempInput);
      }
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } else {
      // 다른 브라우저용 복사 로직
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          setToastMessage('URL이 클립보드에 복사되었습니다.');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        })
        .catch((err) => {
          console.error('클립보드 복사 실패:', err);
          setToastMessage('URL 복사에 실패했습니다.');
          setShowToast(true);
          setTimeout(() => setShowToast(false), 3000);
        });
    }
    setIsShareModalOpen(false);
  };


  if (isLoading) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-12 h-12 border-4 border-[#4ABC85] border-t-transparent rounded-full animate-spin"></div>
      <p className="mt-4 text-gray-600 font-medium animate-pulse">분석 중입니다...</p>
    </div>
  );

  if (isExpired) return (
    <div className="flex flex-col min-h-screen items-center justify-center">
      <div className="w-[316px] max-w-[100vw] rounded-2xl p-6 shadow-[0_0_6px_rgba(0,0,0,0.1)] text-center">
        <p className="text-[18px] font-semibold text-[#231F20] mb-4">
          분석 코드가 만료되었습니다
        </p>
        <p className="text-[14px] text-[#7B7979] mb-6">
          새로운 분석을 시작하시려면 아래 버튼을 눌러주세요
        </p>
        <button
          onClick={() => navigate('/')}
          className="w-full h-[40px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575]"
        >
          메인으로 돌아가기
        </button>
      </div>
    </div>
  );

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      {/* <Header /> */}
      <main className="flex-grow flex flex-col items-center pt-[12px]">
        {/* 로고 */}
        <img
          src={logoAnalysis}
          alt="Analysis Logo"
          className="w-[121px] h-[26px] mb-[40px]"
        />

        {/* 시험 정보 카드 (첫 번째) */}
        <div className="w-[316px] max-w-[100vw] rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
          {examInfo ? (
            <div className="flex flex-col">
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center gap-2">
                  <span className="text-[14px] font-semibold text-[#231F20]">
                    {examInfo.field_info.tree_name}
                  </span>
                  <span className="w-[38px] h-[16px] flex items-center justify-center bg-[#4ABC85] text-[#FFFFFF] rounded-full text-[8px]">
                    {examInfo.exam_info.exam_type}
                  </span>
                </div>
                <span className="text-[12px] text-[#A3A1A2]">
                  {formatExamDate(examInfo.exam_info.updated_at)}
                </span>
              </div>

              <div className="w-full bg-[#f1f1f1] rounded-[16px] flex items-center justify-center px-4 h-[50px]">
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">회차</span>
                    <span className="text-[#231F20] text-[11px]">{examInfo.exam_info.exam_count}</span>
                    <span className="text-[#231F20]/60 text-[8px]">· TID</span>
                    <span className="text-[#231F20] text-[8px]">{examInfo.exam_info.exam_id}</span>
                  </div>
                  <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">난이도</span>
                    <span className="text-[#231F20] font-medium text-[11px]">{(examInfo.exam_info.difficulty / 100).toFixed(2)}</span>
                  </div>
                  <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">문제 수</span>
                    <span className="text-[#231F20] font-medium text-[11px]">{examInfo.exam_info.length}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-[72px] flex items-center justify-center">
              <p className="text-gray-500">시험 정보가 없습니다</p>
            </div>
          )}
        </div>

        {/* 테스트 결과 카드 (두 번째) */}
        <div className="w-[316px] max-w-[100vw] rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
          <p className="text-[14px] font-semibold text-[#231F20] text-center mb-[40px]">
            테스트 결과
          </p>
          <div className="flex justify-center gap-[90px] mb-12">
            {/* 등급 원형 (왼쪽) */}
            <div className="relative w-[67.5px] h-[67.5px]">
              <svg
                className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
                viewBox="0 0 120 120"
              >
                <defs>
                  <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#4ABC85" />
                    <stop offset="100%" stopColor="#4ABC85" />
                  </linearGradient>
                </defs>

                {/* 배경 원 */}
                <circle
                  cx="60"
                  cy="60"
                  r="48.43"
                  fill="none"
                  stroke="#EEEEEE"
                  strokeWidth="13"
                  strokeLinecap="round"
                  strokeDasharray="228.38 76.38"
                  transform="rotate(180, 60, 60)"
                />
                
                {/* 진행도 원 */}
                <circle
                  cx="60"
                  cy="60"
                  r="48.43"
                  fill="none"
                  stroke="url(#progressGradient)"
                  strokeWidth="13"
                  strokeLinecap="round"
                  strokeDasharray={`${228.38 * gradeProgress} ${228.38 * (1 - gradeProgress) + 76.38}`}
                  transform="translate(120, 0) scale(-1, 1) rotate(90, 60, 60)"
                />

                {/* 텍스트 */}
                <text
                  x="30"
                  y="110"
                  fill="#A3A1A2"
                  fontSize="8"
                  textAnchor="start"
                  dominantBaseline="middle"
                  style={{ fontWeight: 500 }}
                >
                  5등급
                </text>

                <text
                  x="18"
                  y="76"
                  fill="#A3A1A2"
                  fontSize="8"
                  textAnchor="end"
                  dominantBaseline="middle"
                  style={{ fontWeight: 500 }}
                >
                  만점
                </text>

                {/* 4개의 점 */}
                {[0.2, 0.4, 0.6, 0.8].map((ratio, index) => (
                  <circle
                    key={index}
                    cx={60 + 48.43 * Math.cos((180 + 270 * ratio) * Math.PI / 180)}
                    cy={60 + 48.43 * Math.sin((180 + 270 * ratio) * Math.PI / 180)}
                    r="1.8"
                    fill="#FFFFFF"
                  />
                ))}

                {/* 움직이는 삼각형 화살표 */}
                <path
                  d="M 0,-4 L 3,2 L -3,2 Z"
                  fill="#4ABC85"
                  transform={`
                    translate(60, 60)
                    rotate(${180 - ((analysis?.percentage || 0) * 270)})
                    translate(0, -32)
                  `}
                />
              </svg>
              <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center z-10">
                <div className="text-center -mt-2">
                  <div className="text-[30px] font-bold text-[#FFFFFF]">
                    {analysis?.target_grade === undefined ? '-' : analysis.target_grade + 1}
                  </div>
                  <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-2">등급</div>
                </div>
              </div>
            </div>

            {/* PR 원형 (오른쪽) */}
            <div className="relative w-[67.5px] h-[67.5px]">
                    <svg
                      className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
                      viewBox="0 0 120 120"
                    >
                      <defs>
                        <linearGradient id="progressGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#4ABC85" />
                          <stop offset="100%" stopColor="#4ABC85" />
                        </linearGradient>
                      </defs>
                      <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="#EEEEEE"
                strokeWidth="13"
                strokeLinecap="round"
                strokeDasharray="228.38 76.38"
                transform="rotate(90, 60, 60)"
              />
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="url(#progressGradient2)"
                strokeWidth="13"
                strokeLinecap="round"
                        strokeDasharray={`${228.38 * (getPrValue() / 10000)} ${228.38 * (1 - (getPrValue() / 10000)) + 76.38}`}
                        transform="rotate(90, 60, 60)"
                        />

                                    {/* 텍스트를 다른 요소들 위에 렌더링되도록 마지막으로 이동 */}
              <text
                x="71"
                y="110"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="start"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                0
              </text>

              <text
                x="120"
                y="76"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="end"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                10000
              </text>
              
                      {/* 4개의 점 */}
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.2) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.2) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.4) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.4) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.6) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.6) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.8) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.8) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      {/* 움직이는 삼각형 화살표 - y축 대칭 */}
                      <path
                        d="M 0,-4 L 3,2 L -3,2 Z"
                        fill="#4ABC85"
                        transform={`
                  translate(60, 60)
                  scale(-1, 1)
                  rotate(${180 - ((getPrValue() / 10000) * 270)}),
                  translate(0, -32)
                `}
                      />
                    </svg>
              <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center">
                <div className="text-center">
                  <div className="text-[18px] font-bold text-[#FFFFFF]">
                    {getPrValue()}
                  </div>
                  <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-1">PR</div>
                </div>
              </div>
            </div>
          </div>

          {/* 구분선 */}
          <div className="w-full h-[1px] bg-[#EEEEEE] mb-4"></div>

          {/* 수직 정보 */}
          <div className="flex flex-col gap-2 mb-4 px-1">
            <div className="flex justify-between">
              <span className="text-[#231F20]/60 text-[12px]">백분위</span>
              <span className="text-[#231F20] font-medium text-[12px]">
                {Math.round((analysis?.percentage || 0) * 100)}%
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#231F20]/60 text-[12px]">다음 등급까지 남은 학습시간</span>
              <span className="text-[#231F20] font-medium text-[12px]">
                {Math.round((analysis?.remain_time || 0) / 60)}시간
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#231F20]/60 text-[12px]">누적 문제 풀이 수</span>
              <span className="text-[#231F20] font-medium text-[12px]">
                {examInfo?.exam_info?.total_record?.[0] || 0} 문제
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-[#231F20]/60 text-[12px]">전체 정답률</span>
              <span className="text-[#231F20] font-medium text-[12px]">
                {examInfo?.exam_info?.total_record?.[1] || 0}%
              </span>
            </div>
          </div>

          {/* 레벨별 정보 */}
          {[1, 2, 3, 4, 5].map((level, index) => (
            <div key={level} className="w-full bg-[#f1f1f1] rounded-[10px] flex items-center px-4 h-[40px] mb-[10px] last:mb-0">
              <div className="w-full grid grid-cols-[60px_1fr] items-center">
                <span className="text-[#231F20] text-[12px] font-semibold">Lv {level}</span>
                <div className="grid grid-cols-3 items-center">
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">정답</span>
                    <span className="text-[#231F20] text-[11px]">{examInfo?.exam_info?.record_acc?.[0]?.[index] || 0}</span>
                    <span className="text-[#231F20]/60 text-[11px]">개</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">오답</span>
                    <span className="text-[#231F20] font-medium text-[11px]">{examInfo?.exam_info?.record_acc?.[1]?.[index] || 0}</span>
                    <span className="text-[#231F20]/60 text-[11px]">개</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <span className="text-[#231F20]/60 text-[11px]">정답률</span>
                    <span className="text-[#231F20] font-medium text-[11px]">{examInfo?.exam_info?.accuracy?.[index] || 0}</span>
                    <span className="text-[#231F20]/60 text-[11px]">%</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* 보완 필요 영역 카드 (세 번째) */}
        <div className="w-[316px] max-w-[100vw] space-y-[20px] mb-[40px]">
          <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
            <p className="text-[14px] font-semibold text-[#231F20] mb-[10px]">
              이 부분은 보완이 필요해요
            </p>

            {analysis?.priority_sector?.length > 0 ? (
              analysis.priority_sector.map((item, index) => (
                <div key={index} className="h-auto bg-[#F8F8F8] rounded-2xl p-4 mb-[10px] last:mb-0">
                  <div className="flex items-center gap-2 mb-1">
                    <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                      <span className="text-[8px] font-semibold text-white">
                        {item.tree_name.replace(/\([^)]*\)/g, '').trim()}
                      </span>
                    </div>
                    <span className="text-[12px] font-medium text-[#7B7979]">
                      {item.parent_name}
                    </span>
                  </div>
                  <p className="text-[14px] font-semibold text-[#231F20]">
                    {item.comp_name.replace(/\([^)]*\)/g, '').trim()}
                  </p>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-4">
                보완이 필요한 부분이 없습니다.
              </div>
            )}

            <button
              onClick={() => navigate(`/result/${code}/map`)}
              className="w-full h-[50px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575] mt-4"
            >
              나의 폼 확인하기
            </button>
          </div>
        </div>
        {/* 버튼 그룹 */}
        <div className="w-[316px] flex gap-[20px] mb-[40px]">
          <button
            onClick={handleShare}
            className="flex-1 h-[60px] flex items-center justify-center space-x-2 bg-[#EEEEEE] text-[#7B7979] rounded-2xl hover:bg-[#E5E5E5]"
          >
            <Share size={20} />
            <span className="font-bold text-sm">공유하기</span>
          </button>
          <button
            onClick={() => navigate(`/signup?referral=${code}`)}
            className="flex-[2] h-[60px] flex items-center justify-center space-x-2 bg-[#4ABC85] text-[#fff] rounded-2xl hover:bg-[#3da575]"
          >
            <TabletSmartphone size={20} />
            <span className="font-bold text-sm">테스트 예약하기</span>
          </button>
        </div>

        {/* 입 모양 곡선 추가 */}
        <div className="absolute left-1/2 top-[350px] -translate-x-1/2 z-10 mb-8">
          <svg width="44" height="40" viewBox="0 0 60 40">
            <path
              d={`M 9 20 Q 29 ${(() => {
                const prProgress = getPrValue() / 10000;

                return Math.abs(gradeProgress - prProgress) <= 0.1
                ? 30  // 위로 휨 (웃는 표정) - 0.1 이하
                : Math.abs(gradeProgress - prProgress) < 0.2
                  ? 20  // 직선 (0.1 초과 ~ 0.2 미만)
                  : 10  // 아래로 휨 (우는 표정) - 0.2 이상
              })()} 51 20`}
              stroke="#4ABC85"
              strokeWidth="13"
              fill="none"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </main>

      {/* 공유하기 모달 */}
      {isShareModalOpen && (
        <div 
          className="fixed inset-0 bg-black/40 flex items-center justify-center z-50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setIsShareModalOpen(false);
            }
          }}
        >
          <div className="w-[316px] bg-white rounded-2xl p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-[18px] font-semibold text-[#231F20]">공유하기</h2>
              <button onClick={() => setIsShareModalOpen(false)}>
                <X size={24} className="text-[#7B7979]" />
              </button>
            </div>
            <div className="bg-[#F8F8F8] rounded-2xl p-4 mb-4">
              <p className="text-[14px] text-[#231F20] break-all">
                {window.location.href}
              </p>
            </div>
            <div className="flex gap-3">
              <button
                onClick={() => setIsShareModalOpen(false)}
                className="flex-1 h-[50px] bg-[#EEEEEE] text-[#7B7979] rounded-2xl text-sm font-bold hover:bg-[#E5E5E5]"
              >
                닫기
              </button>
              <button
                onClick={handleCopyLink}
                className="flex-1 h-[50px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575]"
              >
                링크 복사하기
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 메시지 */}
      {showToast && (
        <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 bg-[#231F20] text-white px-4 py-3 rounded-lg shadow-lg z-[60]">
          <div className="flex items-center space-x-2">
            <span className="text-sm">{toastMessage}</span>
            <button
              onClick={() => setShowToast(false)}
              className="ml-2"
            >
              <X size={16} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResultPage;