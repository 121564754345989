import React, { useRef, useEffect } from 'react';
import logoStudent from '../../assets/logo_student.png';
import { ChevronUp, ChevronDown, Triangle } from 'lucide-react';

function RankingList({ rankings = [], onRankingClick, selectedStampId }) {
  const rankingScrollRef = useRef(null);

  useEffect(() => {
    if (selectedStampId) {
      setTimeout(() => {
        scrollToSelectedCard(selectedStampId);
      }, 100);
    }
  }, [selectedStampId]);

  const scrollToSelectedCard = (stampId) => {
    if (rankingScrollRef.current) {
      const container = rankingScrollRef.current;
      const selectedCard = container.querySelector(`[data-stamp-id="${stampId}"]`);
      
      if (selectedCard) {
        const containerRect = container.getBoundingClientRect();
        const cardRect = selectedCard.getBoundingClientRect();
        
        const scrollPosition = (
          selectedCard.offsetLeft - 
          container.offsetLeft - 
          (containerRect.width - cardRect.width) / 2
        );

        container.scrollTo({
          left: Math.max(0, scrollPosition),
          behavior: 'smooth'
        });
      }
    }
  };

  const handleCardClick = (rankingItem) => {
    onRankingClick(rankingItem);
  };

  return (
    <div className="flex flex-col items-center">
      <div
        ref={rankingScrollRef}
        className="flex overflow-x-auto pb-4 w-[316px]"
        style={{ 
          scrollBehavior: 'smooth',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overflowY: 'hidden'
        }}
        onWheel={(e) => {
          if (rankingScrollRef.current) {
            e.preventDefault();
            rankingScrollRef.current.scrollLeft += e.deltaY;
          }
        }}
      >
        <style jsx>{`
          div::-webkit-scrollbar {
            display: none;
          }
        `}</style>

        {rankings.map((rankingItem) => (
          <div 
            key={rankingItem.stamp_id}
            data-stamp-id={rankingItem.stamp_id}
            className="flex-shrink-0 w-[284px] mr-5 last:mr-0 cursor-pointer hover:opacity-80 transition-all"
            onClick={() => handleCardClick(rankingItem)}
          >
            <div className={`w-[284px] rounded-2xl p-[5px] transition-all ${
              selectedStampId === rankingItem.stamp_id 
                ? 'border-[1px] border-solid border-[#A3A1A2]' 
                : ''
            }`}>
              <div className={`w-[274px] bg-[#FFFFFF] backdrop-blur-md rounded-xl p-3 ${
                selectedStampId !== rankingItem.stamp_id 
                  ? 'shadow-[0_0_6px_0_rgba(0,0,0,0.1)]' 
                  : ''
              }`}>
                <span className="text-[14px] font-semibold text-[#231F20]">{rankingItem.tree_name}</span>
                <div className="w-full mt-2">
                  <div className="flex">
                    <div className="flex items-center">
                      <span className="text-[10px] font-medium text-[#A3A1A2] mr-2">랭킹</span>
                      <div className="flex items-baseline">
                        <span className="text-[14px] font-semibold text-[#231F20]">{rankingItem.rank || '-'}</span>
                        <span className="text-[10px] font-semibold text-[#231F20] ml-0.5">등</span>
                      </div>
                    </div>
                    <div className="flex items-center ml-4">
                      <span className="text-[10px] font-medium text-[#A3A1A2] mr-2">레이팅</span>
                      <div className="flex items-baseline">
                        <span className="text-[14px] font-semibold text-[#231F20]">{rankingItem.rating || '-'}</span>
                        <span className="text-[10px] font-semibold text-[#231F20] ml-0.5">PR</span>
                      </div>
                    </div>
                    <div className="flex items-center ml-4">
                      <div className="flex items-center border border-[#D9D9D9] rounded-lg w-full h-[20px] justify-center px-2">
                        {rankingItem.delta && (
                          <>
                            {Number(rankingItem.delta) > 0 ? (
                              <Triangle className="w-2 h-2 text-red-500 mr-0.5 fill-current rotate-0" />
                            ) : Number(rankingItem.delta) < 0 ? (
                              <Triangle className="w-2 h-2 text-blue-500 mr-0.5 fill-current rotate-180" />
                            ) : null}
                          </>
                        )}
                        <div className="flex items-baseline">
                          <span className="text-[10px] font-semibold text-[#231F20]">{rankingItem.delta ? Math.abs(Number(rankingItem.delta)) : '-'}</span>
                          <span className="text-[6px] font-semibold text-[#231F20] ml-0.5">PR</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RankingList;
