import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import iconHome from '../assets/icon_home.png';
import iconTest from '../assets/icon_test.png';
import iconRank from '../assets/icon_rank.png';
import iconHamburger from '../assets/icon_hamburger.png';

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { icon: iconHome, label: '홈', path: '/home' },
    { icon: iconTest, label: '카이테스트', path: '/kytest' },
    { icon: iconRank, label: '랭킹', path: '/ranking' },
    { icon: iconHamburger, label: '메뉴', path: '/menu' },
  ];

  return (
    <footer className="fixed bottom-0 left-0 right-0 w-full z-50">
      <div className="relative">
        {/* Glass effect background */}
        <div className="absolute inset-0 backdrop-blur-md bg-white/30 border-t border-white/20 rounded-t-[16px] shadow-[0_0_6px_0_rgba(0,0,0,0.1)]" />
        
        {/* Content */}
        <div className="relative max-w-[750px] mx-auto">
          <nav className="w-full py-2 pb-[34px]">
            <ul className="flex justify-between items-center w-full px-6">
              {menuItems.map((item) => (
                <li key={item.path} className="px-2">
                  <button
                    onClick={() => {
                      if (location.pathname === item.path) {
                        window.location.reload();
                      } else {
                        navigate(item.path);
                      }
                    }}
                    className={`flex flex-col items-center p-2 transition-all duration-200`}
                  >
                    <div className={`flex items-center justify-center w-[30px] h-[30px] mb-1 ${
                      location.pathname === item.path 
                        ? 'bg-[#4ABC85] rounded-[8px]' 
                        : ''
                    }`}>
                      <img 
                        src={item.icon} 
                        alt={item.label} 
                        className={`w-6 h-6 ${
                          location.pathname === item.path 
                            ? 'brightness-0 invert' 
                            : 'brightness-0 opacity-[0.64]'
                        }`}
                        style={{
                          filter: location.pathname !== item.path ? 'invert(65%) sepia(11%) saturate(88%) hue-rotate(314deg) brightness(90%) contrast(87%)' : ''
                        }}
                      />
                    </div>
                    <span className={`text-xs ${
                      location.pathname === item.path 
                        ? 'text-[#231F20] font-bold' 
                        : 'text-[#A3A1A2]'
                    }`}>{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
