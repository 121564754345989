import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/newHeader';
import Footer from '../components/Footer';
import axios from 'axios';

function KyTest() {
  const navigate = useNavigate();
  const [testData, setTestData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTree, setSelectedTree] = useState('전체');
  const [treeNames, setTreeNames] = useState(['전체']);

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/student/exams`,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            }
          }
        );

        if (response.data.status === 'success') {
          setTestData(response.data.data);
          const uniqueTreeNames = ['전체', ...new Set(response.data.data.map(item => item.tree_name))];
          setTreeNames(uniqueTreeNames);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      }
    };

    fetchTestData();
  }, [navigate]);

  const filteredTestData = testData?.filter(item => 
    selectedTree === '전체' || item.tree_name === selectedTree
  );

  if (isLoading) return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-700 font-medium animate-pulse">로딩중...</p>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex flex-col items-center pt-[84px] pb-[120px] hide-scrollbar">
          <div className="w-[316px]">
            <h1 className="text-[18px] font-bold text-[#231F20] mb-6">카이테스트</h1>
            <div className="mb-6">
              <select
                value={selectedTree}
                onChange={(e) => setSelectedTree(e.target.value)}
                className={`w-[158px] h-[42px] px-4 rounded-[16px] border border-gray-200 text-[14px] font-medium ${
                  selectedTree === '전체' ? 'text-[#A3A1A2]' : 'text-[#231F20]'
                } bg-white appearance-none cursor-pointer`}
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='${
                    selectedTree === '전체' ? '%23A3A1A2' : '%23231F20'
                  }' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 16px center'
                }}
              >
                {treeNames.map((treeName) => (
                  <option 
                    key={treeName} 
                    value={treeName}
                    className={treeName === selectedTree ? 'text-[#231F20]' : 'text-[#A3A1A2]'}
                  >
                    {treeName}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="space-y-3">
              {filteredTestData && filteredTestData.map((item) => (
                <div key={item.stamp_id} className="space-y-3">
                  {item.exams.map((exam) => (
                    <div 
                      key={exam.exam_id}
                      className={`bg-white rounded-[16px] p-4 border ${
                        exam.status !== 200 
                          ? 'cursor-pointer border-gray-100 hover:border-[#4ABC85] transition-colors' 
                          : 'opacity-50 cursor-not-allowed border-gray-100'
                      }`}
                      onClick={() => {
                        if (exam.status !== 200) {
                          navigate(`/myresult/${exam.exam_id}`);
                        }
                      }}
                    >
                      <div className="flex justify-between items-center mb-3">
                        <div className="flex items-center gap-2">
                          <span className="text-[14px] font-semibold text-[#231F20]">{item.tree_name}</span>
                          <span className="w-[38px] h-[16px] flex items-center justify-center bg-[#4ABC85] text-[#FFFFFF] rounded-full text-[8px]">
                            {exam.exam_type}
                          </span>
                        </div>
                        <span className="text-[12px] text-[#A3A1A2]">
                          {new Date(exam.updated_at).toLocaleDateString()}
                        </span>
                      </div>

                      <div className="w-full bg-[#f1f1f1] rounded-[16px] flex items-center justify-center px-4 h-[50px]">
                      <div className="flex items-center gap-3">
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">회차</span>
                          <span className="text-[#231F20] text-[11px]">{exam.exam_count}</span>
                          <span className="text-[#231F20]/60 text-[8px]">· TID</span>
                          <span className="text-[#231F20] text-[8px]">{exam.exam_id}</span>
                        </div>
                        <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">난이도</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{(exam.difficulty / 100).toFixed(2)}</span>
                        </div>
                        <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">문제 수</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{exam.length}</span>
                        </div>
                      </div>
                    </div>

                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default KyTest;
