import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Clock } from 'lucide-react';

function StampList({ stamps, stampExams }) {
  const navigate = useNavigate();
  const stampScrollRef = useRef(null);

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-[18px] font-bold text-white mb-4 self-start ml-2">카이테스트</h2>
      <div 
        ref={stampScrollRef}
        className="flex overflow-x-auto pb-4 w-full max-w-[384px]"
        style={{ 
          scrollBehavior: 'smooth',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overflowY: 'hidden'
        }}
        onWheel={(e) => {
          if (stampScrollRef.current) {
            e.preventDefault();
            stampScrollRef.current.scrollLeft += e.deltaY;
          }
        }}
      >
        <style jsx>{`
          div::-webkit-scrollbar {
            display: none;
          }
        `}</style>
        
        {stamps.map((stamp) => (
          <div key={stamp.stamp_id} className="flex-shrink-0 w-[320px] pr-5 last:pr-0">
            <div className="relative backdrop-blur-md bg-white/30 rounded-2xl p-6 shadow-lg border border-white/20">
              <div className="absolute inset-0 bg-gradient-to-br from-white/10 to-white/5 rounded-2xl -z-[1]" />
              
              <div className="mb-4">
                <p className="text-[14px] font-bold text-white">{stamp.tree_name}</p>
              </div>
              <div className="space-y-3">
                {stampExams[stamp.stamp_id]?.slice(0, 3).map((exam) => (
                  <div 
                    key={exam.exam_id}
                    onClick={() => exam.exam_status === 999 
                      ? navigate(`/myresult/${exam.exam_id}?stampId=${stamp.stamp_id}`) 
                      : null}
                    className={`relative p-4 rounded-2xl border border-white/20 ${
                      exam.exam_status === 999 
                        ? 'cursor-pointer active:bg-white/30' 
                        : 'cursor-not-allowed opacity-70'
                    } transition-colors`}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-black/10 to-black/5 rounded-2xl -z-[1]" />
                    
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-[12px] font-semibold text-white">
                          Ky TEST · {exam.exam_type?.charAt(0).toUpperCase() + exam.exam_type?.slice(1).toLowerCase()}
                          {exam.is_recent && 
                            <span className="ml-2 text-[8px] bg-[#4ABC85] text-white px-[6px] py-[2px] rounded-[4px]">New</span>
                          }
                          {exam.exam_status !== 999 && 
                            <span className="ml-2 text-[8px] bg-orange-400 text-white px-[6px] py-[2px] rounded-[4px]">
                              진행중
                            </span>
                          }
                        </p>
                        <div className="flex items-center space-x-2 text-[10px] text-white/70">
                          <span>{formatExamDate(exam.updated_at)}</span>
                          <span>|</span>
                          <span>난이도: {(exam.difficulty / 100).toFixed(2)}</span>
                          <span>|</span>
                          <span>문제수: {exam.length}</span>
                        </div>
                      </div>
                      <ChevronRight className="w-5 h-5 text-white/70" />
                    </div>
                  </div>
                ))}
                {(!stampExams[stamp.stamp_id] || stampExams[stamp.stamp_id].length === 0) && (
                  <p className="text-center text-white/70 text-sm">
                    시험 기록이 없습니다
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StampList;
