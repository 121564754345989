import React, { useEffect, useState } from 'react';
import { Share, X, ChevronLeft } from 'lucide-react';
import Header from '../components/newHeader';
import logoAnalysis from '../assets/logo_analysis.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import FloatingNavButtons from '../components/FloatingNavButtons';
import Footer from '../components/Footer';

function MyResultPage() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState(null);
  const [examInfo, setExamInfo] = useState(null);
  const [fieldInfo, setFieldInfo] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [examList, setExamList] = useState([]);
  const [currentExamIndex, setCurrentExamIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [rankData, setRankData] = useState(null);

  // gradeProgress 계산을 상단에서 정의
  const gradeProgress = (() => {
    const targetGrade = analysis?.target_grade;
    const progress = analysis?.progress || 0;

    switch (targetGrade) {
      case 0: return 0.8 + 0.2 * progress;
      case 1: return 0.6 + 0.2 * progress;
      case 2: return 0.4 + 0.2 * progress;
      case 3: return 0.4 * progress;
      default: return progress;
    }
  })();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    const name = localStorage.getItem('student_name');
    const email = localStorage.getItem('student_email');

    if (!token || !name || !email) {
      navigate('/', { replace: true });
      return;
    }

    setToken(token);

    let isMounted = true;

    const fetchData = async () => {
      try {
        const [resultResponse, rankResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/student/resultmap`,
            {
              params: { exam_id: code },
              headers: { 'Authorization': `Bearer ${token}` }
            }
          ),
          axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/api/elotest/rank`,
            {
              headers: { 'Authorization': `Bearer ${token}` }
            }
          )
        ]);

        console.log('Current code:', code);
        console.log('Rank Response:', rankResponse.data.data);

        if (resultResponse.data.status === 'success' && isMounted) {
          const responseData = resultResponse.data.data;
          setAnalysis(responseData.analysis);
          setExamInfo({
            exam_type: responseData.exam_type || '',
            updated_at: responseData.updated_at || new Date().toISOString(),
            exam_count: responseData.exam_count || 0,
            difficulty: responseData.difficulty || '',
            length: responseData.length || 0,
            exam_id: responseData.exam_id || code,
            total_record: responseData.total_record || [],
            record_acc: responseData.record_acc || [],
            accuracy: responseData.accuracy || []
          });
          setFieldInfo({
            tree_name: responseData.tree_name || ''
          });

          // Set rank data
          setRankData(rankResponse.data.data);

          if (responseData.prev_exam !== null || responseData.next_exam !== null) {
            const exams = [];
            if (responseData.prev_exam) exams.push({ exam_id: responseData.prev_exam });
            exams.push({ exam_id: parseInt(code) });
            if (responseData.next_exam) exams.push({ exam_id: responseData.next_exam });
            setExamList(exams);
            setCurrentExamIndex(responseData.prev_exam ? 1 : 0);
          }
          setIsLoading(false);
        }
      } catch (error) {
        console.error('데이터를 불러오는데 실패했습니다:', error);
        setIsLoading(false);
        if (error.response?.status === 401) {
          localStorage.clear();
          navigate('/', { replace: true });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [code, navigate, token]);

  // 렌더링 직전에 상태 확인

  const handleShare = async () => {
    console.log('handleShare 함수 실행됨');
    try {
      const token = localStorage.getItem('access_token');
      console.log('토큰:', token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/resultmap/issue`,
        { exam_id: parseInt(code) },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      console.log('API 응답:', response.data);

      if (response.data) {
        const url = `${window.location.origin}/result/${response.data.data}`;
        setShareUrl(url);
        setIsShareModalOpen(true);
      }
    } catch (error) {
      console.error('에러 발생:', error);
      setToastMessage('결과 공유 링크 생성에 실패했습니다.');
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const copyToClipboard = async (text) => {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      // Safari 전용 복사 로직
      const tempInput = document.createElement('input');
      tempInput.value = text;
      tempInput.style.cssText = 'position: absolute; left: -9999px; top: -9999px';
      document.body.appendChild(tempInput);
      tempInput.focus();
      tempInput.setSelectionRange(0, 99999);

      try {
        // iOS Safari에서는 사용자 제스처(클릭) 내에서 실행되어야 함
        const successful = document.execCommand('copy');
        if (successful) {
          setToastMessage('링크가 복사되었습니다.');
        } else {
          throw new Error('복사 실패');
        }
      } catch (err) {
        setToastMessage('복사에 실패했습니다. 링크를 직접 선택해주세요.');
      } finally {
        document.body.removeChild(tempInput);
      }
    } else {
      // 다른 브라우저용 복사 로직
      try {
        await navigator.clipboard.writeText(text);
        setToastMessage('링크가 복사되었습니다.');
      } catch (err) {
        setToastMessage('복사에 실패했습니다. 링크를 직접 선택해주세요.');
      }
    }
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleBack = () => {
    navigate('/kytest');
  };

  const handlePrevExam = () => {
    if (examList.length > 0 && currentExamIndex > 0) {
      const prevExamId = examList[currentExamIndex - 1].exam_id;
      navigate(`/myresult/${prevExamId}`);
    }
  };

  const handleNextExam = () => {
    if (examList.length > 0 && currentExamIndex < examList.length - 1) {
      const nextExamId = examList[currentExamIndex + 1].exam_id;
      navigate(`/myresult/${nextExamId}`);
    }
  };

  const formatExamDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  };

  // PR 값을 계산하는 부분 수정
  const getPrValue = () => {
    if (!rankData || !code) return "-";
    const examId = parseInt(code);

    // rankData 배열을 순회하면서 exam_list 내에서 해당 exam_id를 가진 시험을 찾음
    for (const rank of rankData) {
      const exam = rank.exam_list?.find(exam => exam.exam_id === examId);
      if (exam) {
        return exam.rating;
      }
    }
    return "-";
  };

  if (isLoading) return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />
      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="w-12 h-12 border-4 border-gray-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-700 font-medium animate-pulse">로딩중...</p>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col min-h-screen">
      <div className="fixed inset-0 bg-[#FAFAFA] z-0" />

      <div className="relative z-20 flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow flex flex-col items-center pt-[84px] pb-[120px]">
          <div className="w-[316px]">
            <div className="flex items-center justify-center mb-6 relative">
              <button
                onClick={handleBack}
                className="absolute left-0 flex items-center text-gray-600 hover:text-gray-800"
              >
                <ChevronLeft size={24} />
              </button>
              <h1 className="text-[18px] font-bold text-[#231F20]">카이테스트</h1>
            </div>

            <div className="w-[316px] space-y-[20px]">
              <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
                {examInfo ? (
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center gap-2">
                        <span className="text-[14px] font-semibold text-[#231F20]">
                          {fieldInfo.tree_name || '과목명'}
                        </span>
                        <span className="w-[38px] h-[16px] flex items-center justify-center bg-[#4ABC85] text-[#FFFFFF] rounded-full text-[8px]">
                          {examInfo.exam_type}
                        </span>
                      </div>
                      <span className="text-[12px] text-[#A3A1A2]">
                        {formatExamDate(examInfo.updated_at)}
                      </span>
                    </div>

                    <div className="w-full bg-[#f1f1f1] rounded-[16px] flex items-center justify-center px-4 h-[50px]">
                      <div className="flex items-center gap-3">
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">회차</span>
                          <span className="text-[#231F20] text-[11px]">{examInfo.exam_count}</span>
                          <span className="text-[#231F20]/60 text-[8px]">· TID</span>
                          <span className="text-[#231F20] text-[8px]">{examInfo.exam_id}</span>
                        </div>
                        <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">난이도</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{(examInfo.difficulty / 100).toFixed(2)}</span>
                        </div>
                        <div className="h-3 w-[1px] bg-[#cccccc]"></div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">문제 수</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{examInfo.length}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="h-[72px] flex items-center justify-center">
                    <p className="text-gray-500">시험 정보가 없습니다</p>
                  </div>
                )}
              </div>

              {/* 새로운 카드 추가 - 원형 그래프 섹션 */}
              <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)] mb-[20px]">
                <p className="text-[14px] font-semibold text-[#231F20] text-center mb-[40px]">
                  테스트 결과
                </p>
                <div className="flex justify-center gap-[90px] mb-12">
                  {/* 등급 원형 (왼쪽) */}
                  <div className="relative w-[67.5px] h-[67.5px]">
                    <svg
                      className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
                      viewBox="0 0 120 120"
                    >
                      <defs>
                        <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#4ABC85" />
                          <stop offset="100%" stopColor="#4ABC85" />
                        </linearGradient>
                      </defs>

              {/* 배경 원 */}
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="#EEEEEE"
                strokeWidth="13"
                strokeLinecap="round"
                strokeDasharray="228.38 76.38"
                transform="rotate(180, 60, 60)"
              />
              
              {/* 진행도 원 */}
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="url(#progressGradient)"
                strokeWidth="13"
                strokeLinecap="round"
                strokeDasharray={`${228.38 * gradeProgress} ${228.38 * (1 - gradeProgress) + 76.38}`}
                transform="translate(120, 0) scale(-1, 1) rotate(90, 60, 60)"
              />

                                    {/* 텍스트를 다른 요소들 위에 렌더링되도록 마지막으로 이동 */}
              <text
               x="30"
                y="110"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="start"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                5등급
              </text>

              <text
                x="18"
                y="76"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="end"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                만점
              </text>

                      {/* 4개의 점 */}
                      <circle
                        cx={60 + 48.43 * Math.cos((180 + 270 * 0.2) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((180 + 270 * 0.2) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((180 + 270 * 0.4) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((180 + 270 * 0.4) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((180 + 270 * 0.6) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((180 + 270 * 0.6) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((180 + 270 * 0.8) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((180 + 270 * 0.8) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />

                      {/* 움직이는 삼각형 화살표 */}
                      <path
                        d="M 0,-4 L 3,2 L -3,2 Z"
                        fill="#4ABC85"
                        transform={`
                  translate(60, 60)
                  rotate(${180 - (gradeProgress * 270)})
                  translate(0, -32)
                `}
                      />

                    </svg>
                    <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center z-10">
                      <div className="text-center -mt-2">
                        <div className="text-[30px] font-bold text-[#FFFFFF]">
                          {analysis?.target_grade === undefined ? '-' : analysis.target_grade + 1}
                        </div>
                        <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-2">등급</div>
                      </div>
                    </div>
                  </div>

                  {/* 입 모양 곡선 */}
                  <div className="absolute left-1/2 top-[410px] -translate-x-1/2 z-10 mb-8">
                    <svg width="44" height="40" viewBox="0 0 60 40">
                      <path
                        d={`M 9 20 Q 29 ${(() => {
                          const prProgress = getPrValue() / 10000;

                          return Math.abs(gradeProgress - prProgress) <= 0.1
                          ? 30  // 위로 휨 (웃는 표정) - 0.1 이하
                          : Math.abs(gradeProgress - prProgress) < 0.2
                            ? 20  // 직선 (0.1 초과 ~ 0.2 미만)
                            : 10  // 아래로 휨 (우는 표정) - 0.2 이상
                        })()} 51 20`}
                        stroke="#4ABC85"
                        strokeWidth="13"
                        fill="none"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>

                  {/* PR 원형 (오른쪽) */}
                  <div className="relative w-[67.5px] h-[67.5px]">
                    <svg
                      className="absolute w-[126px] h-[126px] -left-[29.25px] -top-[29.25px] z-20"
                      viewBox="0 0 120 120"
                    >
                      <defs>
                        <linearGradient id="progressGradient2" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#4ABC85" />
                          <stop offset="100%" stopColor="#4ABC85" />
                        </linearGradient>
                      </defs>
                      <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="#EEEEEE"
                strokeWidth="13"
                strokeLinecap="round"
                strokeDasharray="228.38 76.38"
                transform="rotate(90, 60, 60)"
              />
              <circle
                cx="60"
                cy="60"
                r="48.43"
                fill="none"
                stroke="url(#progressGradient2)"
                strokeWidth="13"
                strokeLinecap="round"
                        strokeDasharray={`${228.38 * (getPrValue() / 10000)} ${228.38 * (1 - (getPrValue() / 10000)) + 76.38}`}
                        transform="rotate(90, 60, 60)"
                        />

                                    {/* 텍스트를 다른 요소들 위에 렌더링되도록 마지막으로 이동 */}
              <text
                x="71"
                y="110"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="start"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                0
              </text>

              <text
                x="120"
                y="76"
                fill="#A3A1A2"
                fontSize="8"
                textAnchor="end"
                dominantBaseline="middle"
                style={{ fontWeight: 500 }}
              >
                10000
              </text>
              
                      {/* 4개의 점 */}
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.2) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.2) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.4) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.4) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.6) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.6) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      <circle
                        cx={60 + 48.43 * Math.cos((90 + 270 * 0.8) * Math.PI / 180)}
                        cy={60 + 48.43 * Math.sin((90 + 270 * 0.8) * Math.PI / 180)}
                        r="1.8"
                        fill="#FFFFFF"
                      />
                      {/* 움직이는 삼각형 화살표 - y축 대칭 */}
                      <path
                        d="M 0,-4 L 3,2 L -3,2 Z"
                        fill="#4ABC85"
                        transform={`
                  translate(60, 60)
                  scale(-1, 1)
                  rotate(${180 - ((getPrValue() / 10000) * 270)}),
                  translate(0, -32)
                `}
                      />
                    </svg>
                    <div className="w-full h-full bg-[#4ABC85] rounded-full flex items-center justify-center">
                      <div className="text-center">
                        <div className="text-[18px] font-bold text-[#FFFFFF]">
                          {getPrValue()}
                        </div>
                        <div className="text-[10px] font-semibold text-[#FFFFFF]/90 -mt-1">PR</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 구분선 */}
                <div className="w-full h-[1px] bg-[#EEEEEE] mb-4"></div>

                {/* 수직 정보 */}
                <div className="flex flex-col gap-2 mb-4 px-1">
                  <div className="flex justify-between">
                    <span className="text-[#231F20]/60 text-[12px]">백분위</span>
                    <span className="text-[#231F20] font-medium text-[12px]">
                      {Math.round((analysis?.percentage || 0) * 100)}%
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#231F20]/60 text-[12px]">다음 등급까지 남은 학습시간</span>
                    <span className="text-[#231F20] font-medium text-[12px]">
                      {Math.round((analysis?.remain_time || 0) / 60)}시간
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#231F20]/60 text-[12px]">누적 문제 풀이 수</span>
                    <span className="text-[#231F20] font-medium text-[12px]">
                      {examInfo?.total_record?.[0] || 0} 문제
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-[#231F20]/60 text-[12px]">전체 정답률</span>
                    <span className="text-[#231F20] font-medium text-[12px]">
                      {examInfo?.total_record?.[1] || 0}%
                    </span>
                  </div>
                </div>


                {[1, 2, 3, 4, 5].map((level, index) => (
                  <div key={level} className="w-full bg-[#f1f1f1] rounded-[10px] flex items-center px-4 h-[40px] mb-[10px] last:mb-0">
                    <div className="w-full grid grid-cols-[60px_1fr] items-center">
                      <span className="text-[#231F20] text-[12px] font-semibold">Lv {level}</span>
                      <div className="grid grid-cols-3 items-center">
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">정답</span>
                          <span className="text-[#231F20] text-[11px]">{examInfo?.record_acc?.[0]?.[index] || 0}</span>
                          <span className="text-[#231F20]/60 text-[11px]">개</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">오답</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{examInfo?.record_acc?.[1]?.[index] || 0}</span>
                          <span className="text-[#231F20]/60 text-[11px]">개</span>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-[#231F20]/60 text-[11px]">정답률</span>
                          <span className="text-[#231F20] font-medium text-[11px]">{examInfo?.accuracy?.[index] || 0}</span>
                          <span className="text-[#231F20]/60 text-[11px]">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full rounded-2xl p-4 shadow-[0_0_6px_rgba(0,0,0,0.1)]">
                <p className="text-[14px] font-semibold text-[#231F20] mb-[10px]">
                  이 부분은 보완이 필요해요
                </p>

                {analysis?.priority_sector?.length > 0 ? (
                  analysis.priority_sector.map((item, index) => (
                    <div key={index} className="h-auto bg-[#F1F1F1] rounded-2xl p-4 mb-[10px] last:mb-0">
                      <div className="flex items-center gap-2 mb-1">
                        <div className="h-[20px] px-2 flex items-center bg-[#4ABC85] rounded-[10px]">
                          <span className="text-[8px] font-semibold text-white">
                            {(item.tree_name || '').replace(/\([^)]*\)/g, '').trim()}
                          </span>
                        </div>
                        <span className="text-[12px] font-medium text-[#7B7979]">
                          {item.parent_name || ''}
                        </span>
                      </div>
                      <p className="text-[14px] font-semibold text-[#231F20]">
                        {(item.comp_name || '').replace(/\([^)]*\)/g, '').trim()}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-500 py-4">
                    보완이 필요한 부분이 없습니다.
                  </div>
                )}

                <button
                  onClick={() => {
                    navigate(`/myresult/${code}/map`);
                  }}
                  className="w-full h-[50px] bg-[#4ABC85] text-white rounded-2xl text-sm font-bold hover:bg-[#3da575] mt-4"
                >
                  나의 폼 확인하기
                </button>
              </div>
            </div>

            <div className="w-[316px] flex gap-[20px] mb-[40px] mt-[20px]">
              <button
                onClick={handleShare}
                className="w-full h-[60px] flex items-center justify-center space-x-2 bg-white text-[#231F20] rounded-2xl hover:bg-gray-50 border border-gray-200"
              >
                <Share size={20} />
                <span className="font-bold text-sm">공유하기</span>
              </button>
            </div>
          </div>
        </main>

        {showToast && (
          <div className="fixed bottom-[20px] left-1/2 transform -translate-x-1/2 bg-[#231F20] text-white px-4 py-3 rounded-lg shadow-lg z-[60]">
            <div className="flex items-center space-x-2">
              <span className="text-sm">{toastMessage}</span>
              <button
                onClick={() => setShowToast(false)}
                className="ml-2"
              >
                <X size={16} />
              </button>
            </div>
          </div>
        )}

        {isShareModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-2xl p-6 w-[90%] max-w-[384px]">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-bold">공유하기</h3>
                <button onClick={() => setIsShareModalOpen(false)}>
                  <X size={24} />
                </button>
              </div>
              <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <p className="text-sm break-all select-all">{shareUrl}</p>
              </div>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => copyToClipboard(shareUrl)}
                  className="w-full bg-[#4ABC85] text-white py-3 rounded-xl font-bold"
                >
                  링크 복사하기
                </button>
                <button
                  onClick={() => setIsShareModalOpen(false)}
                  className="w-full bg-gray-200 text-gray-700 py-3 rounded-xl font-bold"
                >
                  닫기
                </button>
              </div>
            </div>
          </div>
        )}

        {examList.length > 0 && (
          <FloatingNavButtons
            onPrev={handlePrevExam}
            onNext={handleNextExam}
            disablePrev={currentExamIndex === 0}
            disableNext={currentExamIndex === examList.length - 1}
          />
        )}

        <Footer />
      </div>
    </div>
  );
}

export default MyResultPage;